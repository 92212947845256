import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './style.css';
import corona from '../../img/corona.svg';
import { Game } from '../../logic/game';
export const BoardBlock = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [board, setBoard] = React.useState(undefined);
    const [portals, setPortals] = React.useState(undefined);
    const [playersRound, setPlayersRound] = React.useState(undefined);
    const { address } = useParams();
    const history = useNavigate();
    function move(num, t = true) {
        const indEl = document.querySelector('.pixel.numb-' + num + ' .line-2') ?? document.querySelector('.pixel.numb-' + num + ' .line-3');
        // remove all active
        const all2 = document.querySelectorAll('.board .active');
        for (let i = 0; i < all2.length; i++) {
            all2[i].classList.remove('active');
        }
        if (!indEl || t === false) {
            return undefined;
        }
        const ind = indEl.className.replace('line-2 id-', '').replace('line-3 id-', '');
        const all = document.querySelectorAll('.line-2.id-' + ind);
        const all3 = document.querySelectorAll('.line-3.id-' + ind);
        for (let i = 0; i < all.length; i++) {
            const ell = all[i].parentElement;
            if (!ell)
                return undefined;
            ell.classList.add('active');
        }
        for (let i = 0; i < all3.length; i++) {
            const ell = all3[i].parentElement;
            if (!ell)
                return undefined;
            ell.classList.add('active');
        }
        return true;
    }
    useEffect(() => {
        if (!firstRender && props.infoGame && props.infoGame.info && address) {
            setFirstRender(true);
            const localBoard = Game.genArrBoard(Number(props.infoGame.info._board.columns), props.infoGame.info._blueBeams.concat(props.infoGame.info._redBeams));
            setBoard(localBoard[0]);
            setPortals(localBoard[1]);
        }
        console.log(props.infoGame, props.infoGame?.info, address);
    }, [props.infoGame, address]);
    useEffect(() => {
        if (address) {
        }
        else {
            history('/boards');
        }
    }, [address]);
    return (_jsx("div", { className: "board", children: board && portals
            ? board.map((x, key) => (_jsx("div", { className: "line", children: x.map((y, key2) => (_jsxs("div", { className: 'pixel ' + y.type + ' numb-' + y.number, onMouseOver: () => move(y.number), onMouseOut: () => move(y.number, false), children: [y.type === 'win' ? _jsx("img", { src: corona }) : y.number, _jsx("div", { className: "players", children: props.playersRound ? props.playersRound.map((p, key5) => (p.number === y.number ? _jsxs("div", { children: [_jsx("div", { className: 'player in-' + key5 + ' a-' + p.address.toString().replace(':', '') }), _jsx("div", { className: 'move in-' + key5, id: p.address.toString() })] }, key5) : null)) : null }), portals.filter(p => p.portals[1].number === y.number).length > 0
                            ? portals.filter(p => p.portals[1].number === y.number).map((portal, key3) => (_jsx("div", { 
                                // onMouseOver={() => move(portal.id)}
                                className: 'line-2 id-' + portal.id, style: {
                                    width: ((portal.distance ?? 0) * 1) + 'px',
                                    transform: 'rotate(' + portal.rotate + 'deg)'
                                } }, key3)))
                            : portals.filter(p => p.portals[0].number === y.number).map((portal, key3) => (_jsx("div", { className: 'line-3 id-' + portal.id, style: {
                                    width: ((portal.distance ?? 0) * 1) + 'px',
                                    transform: 'rotate(' + ((portal.rotate ?? 0) + 180) + 'deg)'
                                } }, key3)))] }, key2))) }, key)))
            : null }));
};
