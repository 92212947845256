import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ProviderRpcClient } from 'everscale-inpage-provider';
import { EverscaleStandaloneClient } from 'everscale-standalone-client';
import { App } from './App';
import { EverWalletProvider } from './logic/wallet/hook';
const provider = new ProviderRpcClient({
    fallback: () => EverscaleStandaloneClient.create({
        connection: {
            id: 2,
            type: 'graphql',
            data: {
                // create your own project at https://dashboard.evercloud.dev
                endpoints: ['https://mainnet.evercloud.dev/ef45648dfd964b85b1476c3dfee79d11/graphql']
            }
        }
    })
});
const el = document.createElement('div');
document.body.appendChild(el);
// eruda.init({
//     container: el,
//     tool: [ 'console', 'elements' ]
// })
ReactDOM.render(_jsx(BrowserRouter, { basename: '/', children: _jsx(React.StrictMode, { children: _jsx(EverWalletProvider, { ever: provider, children: _jsx(App, {}) }) }) }), document.querySelector('#root'));
